import logo from "./img/logo.png";
import solscan from "./img/solscan.svg";
import raydium from "./img/raydium.svg";
import ds from "./img/ds.svg";
import sol from "./img/sol.svg";
import birdeye from "./img/birdeye.svg";
import dt from "./img/dt.svg";
import ft from "./img/ft.svg";
import ft1 from "./img/ft-1.svg";
import ft2 from "./img/ft-2.svg";
import ft3 from "./img/ft-3.svg";
import ft4 from "./img/ft-4.svg";
import comm from "./img/comm.svg";
import gr1 from "./img/gr-1.svg";
import gr2 from "./img/gr-2.svg";
import gr3 from "./img/gr-3.svg";
import gr4 from "./img/gr-4.svg";
import gr5 from "./img/gr-5.svg";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.scss";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      delay: 100,
      duration: 800,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <header className="bg-[url('./img/bg-header.png')] bg-cover bg-no-repeat py-5">
        <div className="container mx-auto px-2 lg:px-5 flex flex-col gap-5 lg:flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <img className="w-[90px]" src={logo} alt="" />
            <h2 className="flex flex-col text-xl">
              <span className="font-bold">BABYBONK</span>
              <span className="text-primary">DAO</span>
            </h2>
          </div>
          <div className="flex items-center gap-10 font-medium">
            <li>
              <a className="hover:text-primary transition-all" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="hover:text-primary transition-all" href="#features">
                Features
              </a>
            </li>
            <li>
              <a
                className="hover:text-primary transition-all"
                href="https://babybonkdao.gitbook.io/docs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </li>
            <li>
              <a
                className="hover:text-primary transition-all"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
            </li>
          </div>
          <a className="bg-primary py-3.5 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75">
            Buy Now
          </a>
        </div>
      </header>

      <main>
        <section className="py-16 lg:py-20">
          <div className="relative container mx-auto px-2 lg:px-5">
            <div className="absolute w-[1000px] top-1/4 left-1/2 -translate-x-1/2">
              <img src={gr1} alt="" />
            </div>
            <div className="max-w-5xl mx-auto text-center relative">
              <h1 className="text-4xl lg:text-7xl font-bold leading-tight">
                DAO-Based Ecosystem Catalyst for BabyBonk
              </h1>
              <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg lg:text-xl">
                New generation complete Ecosystem exclusively for the BabyBonk
                community on Solana
              </span>
              <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mx-auto mt-12">
                Launch App
              </a>
            </div>
          </div>
        </section>

        <section
          className="pt-12 pb-20"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <div className="relative container mx-auto px-2 lg:px-5">
            <div className="absolute z-30 bg-black py-6 px-8 lg:px-10 font-medium  lg:text-3xl -top-12 left-1/2 -translate-x-1/2 rounded-xl">
              <span className="text-gradient-1">AVAILABLE ON</span>
            </div>
            <div class="slider">
              <div class="slide-track">
                <div class="slide">
                  <img src={solscan} alt="" />
                </div>
                <div class="slide">
                  <img src={raydium} alt="" />
                </div>
                <div class="slide">
                  <img src={ds} alt="" />
                </div>
                <div class="slide">
                  <img src={sol} alt="" />
                </div>
                <div class="slide">
                  <img src={birdeye} alt="" />
                </div>
                <div class="slide">
                  <img src={dt} alt="" />
                </div>
                <div class="slide">
                  <img src={solscan} alt="" />
                </div>
                <div class="slide">
                  <img src={raydium} alt="" />
                </div>
                <div class="slide">
                  <img src={ds} alt="" />
                </div>
                <div class="slide">
                  <img src={sol} alt="" />
                </div>
                <div class="slide">
                  <img src={birdeye} alt="" />
                </div>
                <div class="slide">
                  <img src={dt} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="relative py-20 mb-20"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <div className="absolute max-w-xl -right-32 top-0">
            <img src={gr2} alt="" />
          </div>
          <div className="relative max-w-5xl text-center mx-auto px-2 lg:px-5">
            <h4 className="text-gradient-2 font-bold text-xl">
              How to get started
            </h4>
            <h3 className="text-4xl lg:text-7xl mt-5 font-bold leading-tight">
              What is BabyBonk DAO?
            </h3>
            <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg lg:text-xl">
              BabyBonk DAO is designed to be a community-governed,
              decentralized, autonomous organization connected to a diverse and
              growing blockchain Ecosystem in Solana.
            </span>
            <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mx-auto mt-12">
              Join DAO
            </a>
          </div>
        </section>

        <section className="container mx-auto px-2 lg:px-5 h-[2px] bg-white/25"></section>

        <section className="py-20 relative" id="features">
          <div className="absolute max-w-xl left-0 top-0">
            <img src={gr3} alt="" />
          </div>
          <div className="absolute max-w-xl right-0 top-1/4">
            <img src={gr4} alt="" />
          </div>
          <div className="relative max-w-5xl mx-auto px-2 lg:px-5 text-center">
            <h4 className="text-gradient-4 font-bold text-xl max-w-[237px] mx-auto">
              <img src={ft} alt="" />
            </h4>
            <h3 className="my-7 block font-bold text-2xl lg:text-3xl">
              Key Points of BabyBonk DAO
            </h3>
            <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg lg:text-xl">
              Community-only features are key to BabyBonk DAO. Creates a huge
              difference between the DAO team and the rest of the world
            </span>
          </div>
          <div className="relative container mx-auto px-2 lg:px-5">
            <div className="mt-20 flex flex-col gap-12">
              <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="200"
                className="flex flex-col lg:flex-row gap-10 lg:gap-20 items-center bg-[#121212] py-8 lg:py-10 px-8 lg:px-16 justify-center rounded-3xl"
              >
                <div className="text-left max-w-xl">
                  <h3 className="text-4xl font-bold max-w-sm">
                    Specialized IDO’s Startup Launchpad
                  </h3>
                  <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg text-left">
                    Access Babybonk DAO incubations and other top-tier tokens
                    and NFT projects in the Solana chain before they reach
                    secondary markets.{" "}
                  </span>
                  <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mt-12">
                    Learn More
                  </a>
                </div>
                <div className="max-w-sm">
                  <img src={ft1} alt="" />
                </div>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="300"
                className="flex flex-col lg:flex-row gap-10 lg:gap-20 items-center bg-[#121212] py-8 lg:py-10 px-8 lg:px-16 justify-center rounded-3xl"
              >
                <div className="max-w-sm">
                  <img src={ft2} alt="" />
                </div>
                <div className="text-left max-w-xl">
                  <h3 className="text-4xl font-bold max-w-sm">
                    Personalize Snipe/Trading BOT
                  </h3>
                  <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg text-left">
                    Professional tools for users in the BabyBonk ecosystem.
                    Snipe, trading, transferring and specialized features on the
                    Solana chain.
                  </span>
                  <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mt-12">
                    Access Now
                  </a>
                </div>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="400"
                className="flex flex-col lg:flex-row gap-10 lg:gap-20 items-center bg-[#121212] py-8 lg:py-10 px-8 lg:px-16 justify-center rounded-3xl"
              >
                <div className="text-left max-w-xl">
                  <h3 className="text-4xl font-bold max-w-sm">
                    Delegation Based Staking and LP Lock
                  </h3>
                  <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg text-left">
                    Access staking, vesting, liquidity lock, and other token
                    solutions in a complete Ecosystem. Stake and earn rewards
                    while holding tokens.
                  </span>
                  <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mt-12">
                    Launch App
                  </a>
                </div>
                <div className="max-w-sm">
                  <img src={ft3} alt="" />
                </div>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="500"
                className="flex flex-col lg:flex-row gap-10 lg:gap-20 items-center bg-[#121212] py-8 lg:py-10 px-8 lg:px-16 justify-center rounded-3xl"
              >
                <div className="max-w-sm">
                  <img src={ft4} alt="" />
                </div>
                <div className="text-left max-w-xl">
                  <h3 className="text-4xl font-bold max-w-sm">
                    BabyBonk DAO Team Special Privileges
                  </h3>
                  <span className="block text-white/60 max-w-2xl mx-auto mt-10 text-lg text-left">
                    We’re creating a decentralized autonomous organization where
                    direct democracy plays an important role.​ To join our DAO
                    you must become a Delegate.
                  </span>
                  <a className="bg-primary py-4 px-10 font-bold rounded-lg transition-all hover:bg-white hover:text-primary hover:opacity-75 block w-fit mt-12">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20" data-aos="fade-up" data-aos-duration="800">
          <div className="max-w-5xl text-center mx-auto px-2 lg:px-5">
            <h4 className="text-gradient-4 font-bold text-xl max-w-[230px] mx-auto">
              <img src={comm} alt="" />
            </h4>
            <h3 className="text-3xl lg:text-4xl max-w-xl mx-auto font-bold leading-tight mt-8">
              Stay updated on the latest BabyBonk DAO
            </h3>
            <div className="flex justify-center gap-10 mt-12 items-center">
              <a
                href="https://t.me/BabyBonkDAO_TG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="56"
                  viewBox="0 0 55 56"
                  fill="none"
                >
                  <path
                    d="M27.5626 0H27.4374C12.2841 0 0 12.2878 0 27.4455V27.5707C0 42.7285 12.2841 55.0162 27.4374 55.0162H27.5626C42.7159 55.0162 55 42.7285 55 27.5707V27.4455C55 12.2878 42.7159 0 27.5626 0Z"
                    fill="white"
                  />
                  <path
                    d="M9.13177 26.415C9.20885 26.3765 9.28596 26.3398 9.36111 26.3051C10.6676 25.6999 11.9914 25.1332 13.3132 24.5666C13.3845 24.5666 13.504 24.4837 13.5714 24.4567C13.6735 24.4124 13.7757 24.37 13.8778 24.3256C14.0743 24.2408 14.2709 24.1579 14.4655 24.0731C14.8586 23.9054 15.2497 23.7377 15.6428 23.57L17.9956 22.562C19.5641 21.8912 21.1346 21.2185 22.7031 20.5477C24.2716 19.877 25.842 19.2043 27.4105 18.5335C28.979 17.8628 30.5495 17.1901 32.118 16.5193C33.6865 15.8485 35.257 15.1758 36.8255 14.5051C37.1742 14.3547 37.5519 14.1311 37.9257 14.0656C38.2398 14.0097 38.5462 13.9018 38.8622 13.842C39.4615 13.7283 40.1224 13.682 40.6966 13.9307C40.8951 14.0174 41.0782 14.1389 41.2304 14.2911C41.9588 15.012 41.8566 16.1955 41.7025 17.2093C40.6292 24.2755 39.5559 31.3436 38.4807 38.4098C38.3342 39.3793 38.1338 40.4433 37.3688 41.0562C36.7214 41.5747 35.8003 41.6325 35.0006 41.4128C34.201 41.1911 33.4957 40.7266 32.804 40.2698C29.9348 38.3693 27.0637 36.4688 24.1945 34.5683C23.5124 34.1172 22.7532 33.5274 22.7609 32.7083C22.7648 32.2148 23.0596 31.7754 23.3602 31.3841C25.8536 28.1305 29.4511 25.8946 32.1276 22.7913C32.5053 22.3538 32.8021 21.5635 32.2837 21.311C31.9754 21.1607 31.6208 21.365 31.3395 21.5597C27.8017 24.0172 24.2658 26.4767 20.728 28.9342C19.5738 29.7361 18.3637 30.561 16.9724 30.7576C15.7276 30.935 14.4752 30.588 13.2708 30.2334C12.2611 29.9365 11.2533 29.632 10.2494 29.3178C9.71564 29.152 9.16454 28.9728 8.75218 28.5969C8.33982 28.2211 8.10284 27.5888 8.35141 27.0877C8.50749 26.7735 8.81002 26.575 9.12796 26.4131L9.13177 26.415Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/BabyBonkDAO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="56"
                  viewBox="0 0 55 56"
                  fill="none"
                >
                  <path
                    d="M54.9961 27.5081C54.9961 41.4009 44.701 52.8864 31.3267 54.7502C30.0762 54.9237 28.7968 55.0143 27.4981 55.0143C25.999 55.0143 24.5269 54.8948 23.0933 54.6635C10.0003 52.5549 0 41.1986 0 27.5081C0 12.3162 12.3126 0 27.5 0C42.6874 0 55 12.3162 55 27.5081H54.9961Z"
                    fill="white"
                  />
                  <path
                    d="M11.1526 12.1331L23.8351 29.0943L11.0736 42.885H13.9466L25.1204 30.8117L34.1476 42.885H43.9226L30.5271 24.9697L42.4061 12.1331H39.5332L29.2438 23.2523L20.9295 12.1331H11.1545H11.1526ZM15.3763 14.2494H19.8658L39.6951 40.7687H35.2055L15.3763 14.2494Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="56"
                  viewBox="0 0 55 56"
                  fill="none"
                >
                  <path
                    d="M27.5626 0H27.4374C12.2841 0 0 12.2878 0 27.4455V27.5707C0 42.7285 12.2841 55.0162 27.4374 55.0162H27.5626C42.7158 55.0162 55 42.7285 55 27.5707V27.4455C55 12.2878 42.7158 0 27.5626 0Z"
                    fill="white"
                  />
                  <path
                    d="M41.263 14.8782C38.7311 13.7159 36.018 12.8601 33.1796 12.3706C33.1276 12.3609 33.0755 12.3841 33.0505 12.4322C32.7017 13.0529 32.3144 13.8644 32.0446 14.5004C28.9924 14.0436 25.9556 14.0436 22.965 14.5004C22.6933 13.8489 22.2925 13.0529 21.9418 12.4322C21.9148 12.386 21.8628 12.3628 21.8127 12.3706C18.9763 12.8601 16.2613 13.7159 13.7293 14.8782C13.7081 14.8879 13.6889 14.9033 13.6754 14.9245C8.52664 22.619 7.1161 30.1246 7.80786 37.5377C7.81172 37.5744 7.83099 37.6091 7.85989 37.6303C11.257 40.1263 14.5483 41.6414 17.7778 42.6456C17.8298 42.661 17.8838 42.6417 17.9165 42.5993C18.6796 41.5565 19.3617 40.456 19.9456 39.2975C19.9802 39.2301 19.9455 39.1491 19.8762 39.1221C18.7952 38.7116 17.7681 38.2124 16.7777 37.6457C16.6987 37.5994 16.6929 37.4876 16.7642 37.4336C16.9723 37.2775 17.1804 37.1156 17.3789 36.9518C17.4155 36.9209 17.4656 36.9152 17.508 36.9344C24.0075 39.9028 31.0446 39.9028 37.467 36.9344C37.5094 36.9132 37.5595 36.9209 37.5961 36.9499C37.7946 37.1137 38.0027 37.2775 38.2127 37.4336C38.284 37.4876 38.2802 37.5994 38.2012 37.6457C37.2127 38.2239 36.1837 38.7135 35.1008 39.1221C35.0295 39.1491 34.9986 39.2301 35.0333 39.2995C35.6306 40.454 36.3108 41.5546 37.0604 42.5993C37.0912 42.6437 37.1471 42.6629 37.1991 42.6475C40.4441 41.6433 43.7353 40.1283 47.1324 37.6322C47.1613 37.611 47.1806 37.5763 47.1844 37.5416C48.013 28.972 45.7971 21.528 41.3131 14.9283C41.3016 14.9071 41.2842 14.8898 41.2611 14.8801L41.263 14.8782ZM20.9128 33.0236C18.9551 33.0236 17.3442 31.2272 17.3442 29.0202C17.3442 26.8132 18.9262 25.0168 20.9128 25.0168C22.8995 25.0168 24.5142 26.8306 24.4815 29.0202C24.4815 31.2272 22.8995 33.0236 20.9128 33.0236ZM34.1103 33.0236C32.1525 33.0236 30.5417 31.2272 30.5417 29.0202C30.5417 26.8132 32.1236 25.0168 34.1103 25.0168C36.0969 25.0168 37.7117 26.8306 37.6789 29.0202C37.6789 31.2272 36.1143 33.0236 34.1103 33.0236Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="relative">
        <div className="absolute max-w-xl right-0 top-0">
          <img src={gr1} alt="" />
        </div>
        <div className="relative py-8 border-t border-white/20 container mx-auto px-2 lg:px-5 flex items-center flex-col lg:flex-row gap-5 justify-between">
          <div className="flex items-center gap-2">
            <img className="w-16 lg:w-[90px]" src={logo} alt="" />
            <h2 className="flex text-xl flex-col">
              <span className="font-bold">BABYBONK</span>
              <span className="text-primary">DAO</span>
            </h2>
          </div>
          <div className="flex items-center gap-10 font-medium">
            <li>
              <a className="hover:text-primary transition-all" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="hover:text-primary transition-all" href="#features">
                Features
              </a>
            </li>
            <li>
              <a
                className="hover:text-primary transition-all"
                href="https://babybonkdao.gitbook.io/docs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </li>
            <li>
              <a
                className="hover:text-primary transition-all"
                href="https://t.me/BabyBonkDAO_TG"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
            </li>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
